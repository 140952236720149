<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					쿠폰함
				</span>
			</p>
			<a class="nav__btn">
				<i class="icon icon-ticket--white">포인트</i>
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<span>
								<strong>인기많은만두</strong>님의<br>
								사용가능한 쿠폰
							</span>
						</div>
					</div>
					<div class="mypage__my-point">
						<small>
							내 쿠폰
						</small>
						<strong>3</strong>장
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<div class="mypage__inner">
							<!-- 마이페이지 쿠폰 상세 -->
							<div class="mypage__coupondetail">
								<!-- 쿠폰 등록하기 -->
								<button type="button" class="btn btn-couponregister">
									<span>
										<i class="icon icon-ticket--point"></i>
									</span>
									<strong>
										쿠폰 등록하기
									</strong>
									<span>
										<i class="icon icon-rightbig--blur"></i>
									</span>
								</button>
								<!-- 쿠폰 탭 -->
								<div class="tab tab--half">
									<a href="#">사용가능</a>
									<a href="#" class="active">사용완료</a>
								</div>
								<!-- 쿠폰들 -->
								<div class="mypage__coupon">
									<div class="coupons">
										<!-- [D] 사용된 쿠폰일 경우 coupon--used 클래스 추가-->
										<div class="coupon coupon--used">
											<div class="coupon__info">
												<div class="coupon__category">
													가입축하 쿠폰
												</div>
												<h3 class="coupon__title">
													15% 할인 쿠폰
												</h3>
												<div class="coupon__amount">
													회원가입 기념 최대 1만원 할인
												</div>
												<div class="coupon__date">
													21.11.19일까지 (D-3)
												</div>
											</div>
											<div class="coupon__check">
												<i class="icon icon-check--white"></i>
											</div>
										</div>
										<div class="coupon coupon--used">
											<div class="coupon__info">
												<div class="coupon__category">
													숙박대전 쿠폰
												</div>
												<h3 class="coupon__title">
													15% 할인 쿠폰
												</h3>
												<div class="coupon__amount">
													회원가입 기념 최대 1만원 할인
												</div>
												<div class="coupon__date">
													21.11.19일까지 (D-3)
												</div>
											</div>
											<div class="coupon__check">
												<i class="icon icon-check--white"></i>
											</div>
										</div>
										<div class="coupon coupon--used">
											<div class="coupon__info">
												<div class="coupon__category">
													VIP 쿠폰
												</div>
												<h3 class="coupon__title">
													15% 할인 쿠폰
												</h3>
												<div class="coupon__amount">
													회원가입 기념 최대 1만원 할인
												</div>
												<div class="coupon__date">
													21.11.19일까지 (D-3)
												</div>
											</div>
											<div class="coupon__check">
												<i class="icon icon-check--white"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

